// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page{
    width: 100%;
}

.content{
    height: 100%;
}

.main-content.closed{
    margin-left : 0;
}

.main-content.open{
    margin-left : 260px;
}

.main-content {
    transition: margin-left 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/component/MainContent/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".main-page{\n    width: 100%;\n}\n\n.content{\n    height: 100%;\n}\n\n.main-content.closed{\n    margin-left : 0;\n}\n\n.main-content.open{\n    margin-left : 260px;\n}\n\n.main-content {\n    transition: margin-left 0.5s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
