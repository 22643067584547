// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-button{
    width: 100%;
    background-color: #2f1e1e;
    color: var(--light);
    font-weight: bold;
    border: none;
    padding: 16px;
    text-align: left;
    cursor: pointer;
    box-shadow: inset -5px 0px 4px -5px black;
    transition: color 0.3s;
}

.logout-button:hover {
    background-color: #c71111;
    box-shadow: var(--shadow-inset-default);
}`, "",{"version":3,"sources":["webpack://./src/component/Sidebar/LogoutBtn.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,yCAAyC;IACzC,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,uCAAuC;AAC3C","sourcesContent":[".logout-button{\n    width: 100%;\n    background-color: #2f1e1e;\n    color: var(--light);\n    font-weight: bold;\n    border: none;\n    padding: 16px;\n    text-align: left;\n    cursor: pointer;\n    box-shadow: inset -5px 0px 4px -5px black;\n    transition: color 0.3s;\n}\n\n.logout-button:hover {\n    background-color: #c71111;\n    box-shadow: var(--shadow-inset-default);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
