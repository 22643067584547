// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NewIssueComponent.css */
.new-issue-component {
    position: fixed;
    right: 0;
    top: 0;
    width: 30%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0,0,0,0.3);
    padding: 20px;
    z-index: 1000;
}

.new-issue-component h2 {
    margin-top: 0;
}

.new-issue-component label {
    display: block;
    margin-top: 10px;
}

.new-issue-component input, .new-issue-component select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 4px;
    box-sizing: border-box;
}

.new-issue-component button {
    margin-top: 10px;
    padding: 10px 15px;
}

.assignee-list {
    margin-top: 5px;
    padding: 8px;
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 4px;
}

.assignee-item {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.assignee-item input {
    margin-right: 4px;
    width: 20px;
}

.assignee-item label {
    margin: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/component/MainContent/ProjectInfoPage/IssueBoard/NewIssueComponent.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,eAAe;IACf,QAAQ;IACR,MAAM;IACN,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,sCAAsC;IACtC,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,sBAAsB,EAAE,sBAAsB;IAC9C,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,sBAAsB,EAAE,sBAAsB;IAC9C,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":["/* NewIssueComponent.css */\n.new-issue-component {\n    position: fixed;\n    right: 0;\n    top: 0;\n    width: 30%;\n    height: 100%;\n    background-color: white;\n    box-shadow: -2px 0 5px rgba(0,0,0,0.3);\n    padding: 20px;\n    z-index: 1000;\n}\n\n.new-issue-component h2 {\n    margin-top: 0;\n}\n\n.new-issue-component label {\n    display: block;\n    margin-top: 10px;\n}\n\n.new-issue-component input, .new-issue-component select {\n    width: 100%;\n    padding: 8px;\n    margin-top: 5px;\n    border: 1px solid #ccc; /* Light gray border */\n    border-radius: 4px;\n    box-sizing: border-box;\n}\n\n.new-issue-component button {\n    margin-top: 10px;\n    padding: 10px 15px;\n}\n\n.assignee-list {\n    margin-top: 5px;\n    padding: 8px;\n    border: 1px solid #ccc; /* Light gray border */\n    border-radius: 4px;\n}\n\n.assignee-item {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n}\n\n.assignee-item input {\n    margin-right: 4px;\n    width: 20px;\n}\n\n.assignee-item label {\n    margin: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
