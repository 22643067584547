// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-button {
    width: 100%;
    background-color: var(--darkgrey);
    color: var(--light);
    font-weight: bold;
    border: none;
    padding: 18px;
    text-align: left;
    cursor: pointer;
    box-shadow: inset -5px 0px 4px -5px black;
    transition: color 0.3s;
}

.menu-button:hover {
    background-color: var(--point-color);
    box-shadow: inset 5px 0px 5px -5px black;
}

.menu-button.active {
    background-color: var(--point-color);
    color : var(--light);
    box-shadow: inset 5px 0px 5px -5px black;
}

`, "",{"version":3,"sources":["webpack://./src/component/Sidebar/ProjectBtn.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iCAAiC;IACjC,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,yCAAyC;IACzC,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;IACpC,wCAAwC;AAC5C;;AAEA;IACI,oCAAoC;IACpC,oBAAoB;IACpB,wCAAwC;AAC5C","sourcesContent":[".menu-button {\n    width: 100%;\n    background-color: var(--darkgrey);\n    color: var(--light);\n    font-weight: bold;\n    border: none;\n    padding: 18px;\n    text-align: left;\n    cursor: pointer;\n    box-shadow: inset -5px 0px 4px -5px black;\n    transition: color 0.3s;\n}\n\n.menu-button:hover {\n    background-color: var(--point-color);\n    box-shadow: inset 5px 0px 5px -5px black;\n}\n\n.menu-button.active {\n    background-color: var(--point-color);\n    color : var(--light);\n    box-shadow: inset 5px 0px 5px -5px black;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
