// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-info-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 100%;
}

.project-header h1 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #333;
}

.project-header p {
    font-size: 20px;
    color: #666;
}

.project-header {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 20px;
}

.settings-button {
    margin-bottom: 20px;
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.settings-button:hover {
    background-color: #333;
}`, "",{"version":3,"sources":["webpack://./src/component/MainContent/ProjectInfoPage/ProjectInfoPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".project-info-page {\n    display: flex;\n    flex-direction: column;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    max-width: 100%;\n}\n\n.project-header h1 {\n    font-size: 40px;\n    margin-bottom: 10px;\n    color: #333;\n}\n\n.project-header p {\n    font-size: 20px;\n    color: #666;\n}\n\n.project-header {\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.settings-button {\n    margin-bottom: 20px;\n    background-color: black;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    border-radius: 5px;\n}\n\n.settings-button:hover {\n    background-color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
