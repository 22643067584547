// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header.open{
    padding: 0; /* Padding above and below the text */
}

.header {
    width: 100%;
    background-color: var(--light); /* Light gray background */
    text-align: left; /* Center the logo text */
    padding: 20px; /* Padding above and below the text */
    transition : padding 0.5s;
}

.logo-band h1 {
    font-size: 2rem; /* Size of the logo text */
    font-weight: bold; /* Make the font bold */
}
`, "",{"version":3,"sources":["webpack://./src/component/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,UAAU,EAAE,qCAAqC;AACrD;;AAEA;IACI,WAAW;IACX,8BAA8B,EAAE,0BAA0B;IAC1D,gBAAgB,EAAE,yBAAyB;IAC3C,aAAa,EAAE,qCAAqC;IACpD,yBAAyB;AAC7B;;AAEA;IACI,eAAe,EAAE,0BAA0B;IAC3C,iBAAiB,EAAE,uBAAuB;AAC9C","sourcesContent":[".header.open{\n    padding: 0; /* Padding above and below the text */\n}\n\n.header {\n    width: 100%;\n    background-color: var(--light); /* Light gray background */\n    text-align: left; /* Center the logo text */\n    padding: 20px; /* Padding above and below the text */\n    transition : padding 0.5s;\n}\n\n.logo-band h1 {\n    font-size: 2rem; /* Size of the logo text */\n    font-weight: bold; /* Make the font bold */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
