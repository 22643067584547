import React from 'react';
import './MainPage.css';

function MainPage() {
    return (
        <div>
            <h1>Hello</h1>
        </div>
    );
}

export default MainPage;
