// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
}

:root{
    --light: #f0f0f0;
    --grey: #8d8c8a;
    --darkgrey: #4e4e4e;
    --semiblack: #333333;
    --point-color: #6c63ff;
    --point-color-sub: #4f49bd;


    --shadow-default: 0 0 8px -4px black ;
    --shadow-inset-default: inset 0 0 8px -4px black ;
    background-color: var(--light);
}

#root{
    display: flex;
    flex-grow: 1;
    height: 100%;
}
html{
    display: flex;
    flex-grow: 1;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--light);
}

.app {
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

button i {
    margin-right: 8px; /* 아이콘과 텍스트 사이의 간격 */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;IAGI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,oBAAoB;IACpB,sBAAsB;IACtB,0BAA0B;;;IAG1B,qCAAqC;IACrC,iDAAiD;IACjD,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;AACA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;AACA;IACI,SAAS;IACT,UAAU;IACV,gCAAgC;IAChC,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,iBAAiB,EAAE,oBAAoB;AAC3C","sourcesContent":["*,\n*::before,\n*::after{\n    margin: 0;\n    padding: 0;\n    border: none;\n    box-sizing: border-box;\n}\n\n:root{\n    --light: #f0f0f0;\n    --grey: #8d8c8a;\n    --darkgrey: #4e4e4e;\n    --semiblack: #333333;\n    --point-color: #6c63ff;\n    --point-color-sub: #4f49bd;\n\n\n    --shadow-default: 0 0 8px -4px black ;\n    --shadow-inset-default: inset 0 0 8px -4px black ;\n    background-color: var(--light);\n}\n\n#root{\n    display: flex;\n    flex-grow: 1;\n    height: 100%;\n}\nhtml{\n    display: flex;\n    flex-grow: 1;\n    height: 100%;\n}\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: 'Arial', sans-serif;\n    display: flex;\n    flex-grow: 1;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    background-color: var(--light);\n}\n\n.app {\n    display: flex;\n    flex-grow: 1;\n    height: 100%;\n    width: 100%;\n}\n\nbutton i {\n    margin-right: 8px; /* 아이콘과 텍스트 사이의 간격 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
