// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.issue-board-summary {
    font-family: Arial, sans-serif;
    width: 100%;
}

.view-more-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.view-more-button {
    height: 100%;
    margin-left: 4px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    transition: background-color 0.3s;
    text-align: center;
}

.view-more-button:hover {
    background-color: #0056b3;
}

.new-issue-button {
    margin-left: 4px;
    height: 100%;
    padding: 8px 16px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    transition: background-color 0.3s;
    text-align: center;
}

.new-issue-button:hover {
    background-color: #218838;
}
`, "",{"version":3,"sources":["webpack://./src/component/MainContent/ProjectInfoPage/ProjectComponent/IssueBoardSummary.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,iCAAiC;IACjC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,iCAAiC;IACjC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".issue-board-summary {\n    font-family: Arial, sans-serif;\n    width: 100%;\n}\n\n.view-more-container {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 20px;\n}\n\n.view-more-button {\n    height: 100%;\n    margin-left: 4px;\n    padding: 8px 16px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    cursor: pointer;\n    font-size: 14px;\n    border-radius: 4px;\n    transition: background-color 0.3s;\n    text-align: center;\n}\n\n.view-more-button:hover {\n    background-color: #0056b3;\n}\n\n.new-issue-button {\n    margin-left: 4px;\n    height: 100%;\n    padding: 8px 16px;\n    background-color: #28a745;\n    color: white;\n    border: none;\n    cursor: pointer;\n    font-size: 14px;\n    border-radius: 4px;\n    transition: background-color 0.3s;\n    text-align: center;\n}\n\n.new-issue-button:hover {\n    background-color: #218838;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
